import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Loader from 'componentes/loader'
import Input from 'componentes/input'
import withParams from 'componentes/WithParams'
import { Alert } from 'utiles/functions'
import DB from 'utiles/db'
const waste = new DB('waste')

class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: true,
			title: '',
			descripcion: '',
			unit: '',
			price_unit: '',
			points_unit: '',
			link: '',
			name_link: '',
			status: false
		}
	}

	componentDidMount() {
		waste
			.get({ uuid: this.props.params.uuid })
			.then(data => {
				if (data.status) {
					this.setState({
						title: data.data.Item.title,
						descripcion: data.data.Item.descripcion,
						unit: data.data.Item.unit,
						price_unit: data.data.Item.price_unit,
						points_unit: data.data.Item.points_unit,
						link: data.data.Item.link,
						name_link: data.data.Item.name_link,
						status: data.data.Item.status,
						loading: false
					})
				} else {
					this.setState({ loading: false })
					Alert('¡Ooops! tenemos porblemas de conexión')
				}
			})
			.catch(err => {
				console.log(err)
				this.setState({ loading: false })
				Alert('¡Ooops! tenemos porblemas de conexión')
			})
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		if (
			data.get('title') !== '' &&
			data.get('descripcion') !== '' &&
			data.get('unit') !== '' &&
			data.get('price_unit') !== '' &&
			data.get('points_unit') !== ''
		) {
			this.setState({ loading: true }, () => {
				waste
					.update(
						{
							title: data.get('title'),
							descripcion: data.get('descripcion'),
							unit: data.get('unit'),
							price_unit: Number(data.get('price_unit')),
							points_unit: Number(data.get('points_unit')),
							link: data.get('link'),
							name_link: data.get('name_link'),
							status: this.state.status
						},
						{ uuid: this.props.params.uuid }
					)
					.then(data => {
						if (data.status) {
							this.props.navigate('admin/typewaste')
						} else {
							Alert('¡Ooops! tenemos problemas de conexión')
							this.setState({ loading: false })
						}
					})
					.catch(err => {
						console.log(err)
						Alert('¡Ooops! tenemos problemas de conexión')
						this.setState({ loading: false })
					})
			})
		} else {
			Alert('Debes completar la informacion', 'danger')
		}
	}

	render() {
		return (
			<form action="#" onSubmit={this.handleSubmit}>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<NavLink to="/admin/typewaste" className="back">
							<img src={require('img/back.svg').default} width={25} height={25} />
						</NavLink>
						<h2>Nuevo tipo de residuo</h2>
					</div>
					<div className="btns">
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<Input type="text" title="Titulo del residuo *" placeholder="Ingresa el titulo del residuo" name="title" value={this.state.title} />
							<Input
								type="textarea"
								title="Descripción del residuo *"
								placeholder="Ingresa la descripción"
								name="descripcion"
								value={this.state.descripcion}
							/>
							<Input
								type="select"
								title="Unidad de media *"
								options={[
									{ val: 'G', text: 'Gramo' },
									{ val: 'LB', text: 'Libra' },
									{ val: 'KG', text: 'Kilogramo' },
									{ val: 'ML', text: 'Mililitro' },
									{ val: 'L', text: 'Litro' }
								]}
								name="unit"
								value={this.state.unit}
							/>
						</div>
						<div className="column">
							<Input
								type="number"
								title="Precio por unidad *"
								placeholder="Ingresa el precio por unidad"
								name="price_unit"
								value={this.state.price_unit}
							/>
							<Input
								type="decimal"
								title="Puntos por unidad *"
								placeholder="Ingresa los puntos por unidad"
								name="points_unit"
								value={this.state.points_unit}
							/>
							<Input type="checkbox" title="Estado del residuo" name="status" change={v => this.setState({ status: v })} active={this.state.status} />
						</div>
					</div>
					<div className="divider"></div>
					<div className="columns">
						<div className="column">
							<Input type="text" title="Link (opcional)" placeholder="Ingresa el link" name="link" value={this.state.link} />
						</div>
						<div className="column">
							<Input type="text" title="Nombre del link" placeholder="Ingresa el nombre del link" name="name_link" value={this.state.name_link} />
						</div>
					</div>
					<div className="divider"></div>
					<button className="btn" type="submit">
						Guardar
					</button>
				</div>
			</form>
		)
	}
}
export default withParams(Page)
