import { Component } from 'react'
import { NavLink } from 'react-router-dom'
import Loader from 'componentes/loader'
import Input from 'componentes/input'
import { Alert } from 'utiles/functions'
import { v4 as uuidv4 } from 'uuid'
import DB from 'utiles/db'
const waste = new DB('waste')

export default class Page extends Component {
	constructor(props) {
		super(props)
		this.state = {
			loading: false,
			status: false
		}
	}

	handleSubmit = event => {
		event.preventDefault()
		const data = new FormData(event.currentTarget)
		if (
			data.get('title') !== '' &&
			data.get('descripcion') !== '' &&
			data.get('unit') !== '' &&
			data.get('price_unit') !== '' &&
			data.get('points_unit') !== ''
		) {
			this.setState({ loading: true }, () => {
				waste
					.create({
						uuid: uuidv4(),
						title: data.get('title'),
						descripcion: data.get('descripcion'),
						unit: data.get('unit'),
						price_unit: Number(data.get('price_unit')),
						points_unit: Number(data.get('points_unit')),
						link: data.get('link'),
						name_link: data.get('name_link'),
						status: this.state.status
					})
					.then(data => {
						if (data.status) {
							this.props.navigate('admin/typewaste')
						} else {
							Alert('¡Ooops! tenemos problemas de conexión')
							this.setState({ loading: false })
						}
					})
					.catch(err => {
						console.log(err)
						Alert('¡Ooops! tenemos problemas de conexión')
						this.setState({ loading: false })
					})
			})
		} else {
			Alert('Debes completar la informacion', 'danger')
		}
	}

	render() {
		return (
			<form action="#" onSubmit={this.handleSubmit}>
				<Loader active={this.state.loading} message="Cargando..." />
				<div className="title-page">
					<div className="title">
						<NavLink to="/admin/typewaste" className="back">
							<img src={require('img/back.svg').default} width={25} height={25} />
						</NavLink>
						<h2>Nuevo tipo de residuo</h2>
					</div>
					<div className="btns">
						<button className="btn" type="submit">
							Guardar
						</button>
					</div>
				</div>
				<div className="card">
					<div className="columns">
						<div className="column">
							<Input type="text" title="Titulo del residuo *" placeholder="Ingresa el titulo del residuo" name="title" />
							<Input type="textarea" title="Descripción del residuo *" placeholder="Ingresa la descripción" name="descripcion" />
							<Input
								type="select"
								title="Unidad de media *"
								options={[
									{ val: 'G', text: 'Gramo' },
									{ val: 'LB', text: 'Libra' },
									{ val: 'KG', text: 'Kilogramo' },
									{ val: 'ML', text: 'Mililitro' },
									{ val: 'L', text: 'Litro' }
								]}
								name="unit"
							/>
						</div>
						<div className="column">
							<Input type="number" title="Precio por unidad *" placeholder="Ingresa el precio por unidad" name="price_unit" />
							<Input type="decimal" title="Puntos por unidad *" placeholder="Ingresa los puntos por unidad" name="points_unit" />
							<Input type="checkbox" title="Estado del residuo" name="status" change={v => this.setState({ status: v })} active={this.state.status} />
						</div>
					</div>
					<div className="divider"></div>
					<div className="columns">
						<div className="column">
							<Input type="text" title="Link (opcional)" placeholder="Ingresa el link" name="link" />
						</div>
						<div className="column">
							<Input type="text" title="Nombre del link" placeholder="Ingresa el nombre del link" name="name_link" />
						</div>
					</div>
					<div className="divider"></div>
					<button className="btn" type="submit">
						Guardar
					</button>
				</div>
			</form>
		)
	}
}
